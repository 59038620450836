/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, Menu, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Text, SeparateLineWrap, ContactForm, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Blog - múzeum online"} description={"Blog - múzeum online"}>
        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} name={"uvod1"} layout={"l16"}>
        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"uvod"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" anim={"3"}>
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: rgb(255, 255, 255);\"><a href=\"/\">Tribečské múzeum v Topoľčanoch</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"rgba(245,166,35,1)"}} name={"k18md056r9"}>
        </SeparateLine>


        <Column className="--menu bg--center-h --parallax" style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} anim={null} menu={true} name={"menu"} animS={null} parallax={true}>
          
          <Menu className="--full" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box --hvr5 js-anim  --anim3 --anim-s8" anim={"3"} animS={"8"}>
              
              <Image className="--center" style={{"maxWidth":100}} src={"https://cdn.swbpg.com/t/7746/163d15be2ac241ea948aa3033d3dbbda_s=660x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/7746/163d15be2ac241ea948aa3033d3dbbda_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/163d15be2ac241ea948aa3033d3dbbda_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/163d15be2ac241ea948aa3033d3dbbda_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox style={{"marginTop":0,"paddingTop":0,"marginBottom":70,"paddingBottom":16.8828125}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/"} target={""} content={"<div>Hlavná stránka</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/sluzby"} target={""} content={"<div>Služby</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/kontakt"} target={""} content={"<div>Kontakt</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/kniznica"} target={""} content={"<div>Knižnica</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/projekty"} target={""} content={"<div>Projekty</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/podujatia"} target={""} content={"<div>Podujatia</div>"}>
                </Button>

                <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--900 ls--02 lh--1" style={{"style":5}} href={"/#vystavy"} target={""} content={"<div>Výstavy</div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"oqacvpj25lp"}>
          
          <ColumnWrap className="column__flex --left el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" style={{"maxWidth":424,"paddingBottom":0}} anim={"5"}>
              
              <Title className="title-box title-box--left ff--3 fs--26" style={{"maxWidth":954,"paddingTop":7,"paddingBottom":3.78125,"backgroundColor":"rgba(147, 56, 124, 1)"}} content={"<span style=\"color: rgb(255, 255, 255);\">BLOG - MÚZEUM ONLINE</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"oqacvpj25lp"}>
          
          <ColumnWrap className="column__flex --right el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --right" style={{"maxWidth":425,"paddingBottom":0}} anim={"2"}>
              
              <Title className="title-box title-box--left ff--3 fs--22" style={{"maxWidth":954,"backgroundColor":"rgba(245,166,35,1)"}} content={"<span style=\"font-weight: bold;\">Predmet mesiaca</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-glj9p2 css-mcgqxx --center --parallax" style={{"marginTop":0,"paddingTop":60,"paddingBottom":63}} name={"jli3gnjd0a"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=3000x_.png);
    }
  
background-position: 48% 55%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --left" anim={"2"} animS={"5"}>
              
              <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--14 btn-box--invert" use={"page"} href={"/predmet-mesiaca"} content={"<span style=\"font-weight: bold;\">VIAC</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":15.875,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"uywvokpr0wf"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":553}} anim={null}>
              
              <Title className="title-box title-box--right ff--3 fs--30 swpf--uppercase" style={{"maxWidth":722,"paddingRight":0}} content={"<span style=\"font-weight: bold; color: rgb(155, 155, 155);\">PODCAST</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":27,"marginBottom":0,"paddingBottom":31.0234375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"podcast"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingTop":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim4 --right" style={{"maxWidth":454,"marginTop":75.0390625,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} anim={"4"}>
              
              <Text className="text-box ff--3 fs--18" style={{"paddingBottom":0}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 js-anim  --anim5" style={{"maxWidth":567,"marginTop":0,"paddingTop":0}} anim={"5"}>
              
              <Image className="--center" style={{"maxWidth":173,"marginTop":14.796875,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/7746/cd286767929e4952a9cb7b5266e840b4_s=860x_.png"} url={"https://tribecskem.podbean.com/"} use={"url"} href={"https://tribecskem.podbean.com/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7746/cd286767929e4952a9cb7b5266e840b4_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/cd286767929e4952a9cb7b5266e840b4_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/cd286767929e4952a9cb7b5266e840b4_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/cd286767929e4952a9cb7b5266e840b4_s=1400x_.png 1400w"} position={{"x":"-2.588235294117647%","y":"-1.9704433497536946%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":15.875,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"uywvokpr0wf"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":553}} anim={null}>
              
              <Title className="title-box title-box--right ff--3 fs--30 swpf--uppercase" style={{"maxWidth":722}} content={"<span style=\"font-weight: bold; color: rgb(155, 155, 155);\">Články</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":27,"marginBottom":0,"paddingBottom":31.0234375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"clanok"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingTop":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":454,"marginTop":75.0390625,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Text className="text-box text-box--left ff--3 fs--18" style={{"maxWidth":334}} content={"<span style=\"color: var(--black);\">Autor textu:&nbsp;Daibau časopis</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" style={{"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Mesto leží na západnom Slovensku, v Nitrianskom kraji. Rozprestiera sa na pravom brehu rieky Nitry, obkolesené pohorím Tribeč a Považským Inovcom. Môže sa pochváliť mnohými architektonickými skvostmi, niektoré z nich spomenieme v tomto článku. Svojim občanom ale aj návštevníkom...</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--right ff--3 fs--13" style={{"maxWidth":424,"marginTop":0,"paddingTop":0}} url={"https://www.daibau.sk/clanok/1621/topolcany_-_malebne_mestecko_v_udoli_rieky_nitry"} use={"url"} href={"https://www.daibau.sk/clanok/1621/topolcany_-_malebne_mestecko_v_udoli_rieky_nitry"} content={"<span style=\"font-weight: bold;\">ČÍTAJTE ĎALEJ</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5" style={{"maxWidth":567,"marginTop":0,"paddingTop":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--22" style={{"marginTop":0,"paddingLeft":0,"marginBottom":6,"paddingBottom":0}} content={"<span style=\"font-weight: bold;\">Topoľčany - malebné mestečko v údolí rieky Nitry</span><br>"}>
              </Title>

              <Image className="--left" style={{"maxWidth":349,"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/7746/414014769a6b495ab80b57ab429f2fcd_s=660x_.jpg"} url={"https://www.daibau.sk/clanok/1621/topolcany_-_malebne_mestecko_v_udoli_rieky_nitry"} use={"url"} href={"https://www.daibau.sk/clanok/1621/topolcany_-_malebne_mestecko_v_udoli_rieky_nitry"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7746/414014769a6b495ab80b57ab429f2fcd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7746/414014769a6b495ab80b57ab429f2fcd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7746/414014769a6b495ab80b57ab429f2fcd_s=860x_.jpg 860w"} position={{"x":"-2.588235294117647%","y":"-1.9704433497536946%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bvihpda8sv9"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --right el--1" style={{"maxWidth":1359}} anim={"4"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":912,"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--right ff--3 fs--8 w--300 ls--004 lh--1" style={{"maxWidth":1393,"backgroundColor":"rgba(155,155,155,1)"}} content={""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":27,"marginBottom":0,"paddingBottom":31.0234375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"clanok"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingTop":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":454,"marginTop":75.0390625,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Text className="text-box text-box--left ff--3 fs--18" style={{"maxWidth":334}} content={"<span style=\"color: var(--black);\">Autor textu:&nbsp;Mgr. Ondrej Godál&nbsp;</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" style={{"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Šabľa patrí medzi chladné zbrane, s ktorými sa nielen bežní návštevníci múzeí či rôznych hradov, zámkov a kašťieľov, ale aj diváci rôznych akcií a podujatí môžu zoznámiť aj v dnešnej dobe. Dnes však ide hlavne o zbraň ceremoniálnu, doplnok slávnostnej uniformy napr. u vojakov, prípadne...&nbsp;</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--right ff--3 fs--13" style={{"maxWidth":424,"marginTop":0,"paddingTop":0}} href={"/sekciu-pripravujeme"} content={"<span style=\"font-weight: bold;\">ČÍTAJTE ĎALEJ</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5" style={{"maxWidth":567,"marginTop":0,"paddingTop":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--22" style={{"marginTop":0,"paddingLeft":0,"marginBottom":6,"paddingBottom":0}} content={"<span style=\"font-weight: bold;\">Šabľa - viac než 1000 ročný spoločník</span><br>"}>
              </Title>

              <Image className="--left" style={{"maxWidth":349,"marginTop":0,"paddingTop":76,"marginBottom":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/7746/c469a5f5f39c465dad802db1e7274dc0_s=860x_.png"} href={"/sekciu-pripravujeme"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7746/c469a5f5f39c465dad802db1e7274dc0_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/c469a5f5f39c465dad802db1e7274dc0_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/c469a5f5f39c465dad802db1e7274dc0_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/c469a5f5f39c465dad802db1e7274dc0_s=1400x_.png 1400w, https://cdn.swbpg.com/t/7746/c469a5f5f39c465dad802db1e7274dc0_s=2000x_.png 2000w"} position={{"x":"-2.588235294117647%","y":"-1.9704433497536946%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bvihpda8sv9"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --right el--1" style={{"maxWidth":1359}} anim={"4"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":912,"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--right ff--3 fs--8 w--300 ls--004 lh--1" style={{"maxWidth":1393,"backgroundColor":"rgba(155,155,155,1)"}} content={""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":27,"marginBottom":0,"paddingBottom":31.0234375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"clanok"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingTop":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":454,"marginTop":75.0390625,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Text className="text-box text-box--left ff--3 fs--18" style={{"maxWidth":334}} content={"<span style=\"color: rgb(0, 0, 0);\">Autor textu:&nbsp;</span><span style=\"color: var(--black);\">Ing. Oľga Kostolná&nbsp;</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" style={{"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Prvosienka je jedným zo symbolov jari. Naznačuje to aj odvodený latinský názov <span style=\"font-style: italic;\">primus</span> - prvý a <span style=\"font-style: italic;\">ver</span> - jar. Nachádzame ju na presvetlených stanovištiach, na okrajoch lesov, krovín a lúk. S prichádzajúcimi dlhými dňami a teplejším slnkom sa jej otvárajú omamne voňajúce a medonosné...</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--right ff--3 fs--13" style={{"maxWidth":424,"marginTop":0,"paddingTop":0}} href={"/blog-clanok-botanika"} content={"<span style=\"font-weight: bold;\">ČÍTAJTE ĎALEJ</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5" style={{"maxWidth":567,"marginTop":0,"paddingTop":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--22" style={{"marginTop":0,"paddingLeft":0,"marginBottom":6,"paddingBottom":0}} content={"<span style=\"font-weight: bold;\">Liečivé rastliny</span><br>"}>
              </Title>

              <Image className="--left" style={{"maxWidth":349,"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/7746/3e892140e30e4493a004511c7551bd90_s=660x_.jpeg"} href={"/blog-clanok-botanika"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7746/3e892140e30e4493a004511c7551bd90_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7746/3e892140e30e4493a004511c7551bd90_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7746/3e892140e30e4493a004511c7551bd90_s=860x_.jpeg 860w"} position={{"x":"-2.588235294117647%","y":"-1.9704433497536946%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bvihpda8sv9"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --right el--1" style={{"maxWidth":1359}} anim={"4"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":912,"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--right ff--3 fs--8 w--300 ls--004 lh--1" style={{"maxWidth":1393,"backgroundColor":"rgba(155,155,155,1)"}} content={""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":27,"marginBottom":0,"paddingBottom":31.0234375,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"clanok"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingTop":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"}>
            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":454,"marginTop":75.0390625,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Text className="text-box text-box--left ff--3 fs--18" style={{"maxWidth":334}} content={"<span style=\"color: rgb(0, 0, 0);\">Autor textu:&nbsp;Mgr. Ondrej Godál&nbsp;</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" style={{"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Je známym faktom, že pivo patrí medzi najstaršie cielene pripravované nápoje ľudstva (ak nie je úplne najstarším). Zo svojej pravlasti, pravdepodobne Mezopotámie, si pivo postupne začalo raziť svoju cestu do všetkých kútov vtedy známeho sveta. A o tom, že šlo o cestu...</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--right ff--3 fs--13" style={{"maxWidth":424,"marginTop":0,"paddingTop":0}} href={"/blog-clanok-pivovarnictvo"} content={"<span style=\"font-weight: bold;\">ČÍTAJTE ĎALEJ</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5" style={{"maxWidth":567,"marginTop":0,"paddingTop":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--22" style={{"marginTop":0,"paddingLeft":0,"marginBottom":6,"paddingBottom":0}} content={"<span style=\"font-weight: bold;\">Staré pivovary v oblasti Topoľčian</span><br>"}>
              </Title>

              <Image className="--left" style={{"maxWidth":349,"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/7746/2e049cd23e2d4aecb8d9f9f53b295138_s=860x_.JPG"} svg={false} href={"/blog-clanok-pivovarnictvo"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7746/2e049cd23e2d4aecb8d9f9f53b295138_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7746/2e049cd23e2d4aecb8d9f9f53b295138_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7746/2e049cd23e2d4aecb8d9f9f53b295138_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7746/2e049cd23e2d4aecb8d9f9f53b295138_s=1400x_.JPG 1400w"} position={{"x":"-2.588235294117647%","y":"-1.9704433497536946%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bvihpda8sv9"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --right el--1" style={{"maxWidth":1359}} anim={"4"} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":912,"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box title-box--right ff--3 fs--8 w--300 ls--004 lh--1" style={{"maxWidth":1393,"backgroundColor":"rgba(155,155,155,1)"}} content={""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":23,"marginBottom":0,"paddingBottom":0.9921875,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"clanok"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"marginBottom":26,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":454,"marginTop":74.9140625,"paddingTop":0,"marginBottom":0,"paddingBottom":0}}>
              
              <Text className="text-box text-box--left ff--3 fs--18" style={{"maxWidth":334}} content={"<span style=\"color: rgb(0, 0, 0);\">Autor textu:&nbsp;Mgr.&nbsp;Bohuš Sasko</span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"color: rgb(0, 0, 0);\">Obec Veľké Topoľčany mala taktiež svoj obecný hostinec. Nachádzal sa na Legionárskom námestí č. 9 (dnes priestory oproti Tribečskému múzeu na Krušovskej ulici vedľa potravín). Tento hostinec obec dávala do prenájmu, obyčajne na 3 roky. Dražba prenájmu sa konala aj 3. apríla 1923...</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--right ff--3 fs--13" style={{"marginTop":0}} href={"/blog-clanok-historia"} content={"<span style=\"font-weight: bold;\">ČÍTAJTE ĎALEJ</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5" style={{"maxWidth":509,"paddingTop":0}}>
              
              <Title className="title-box title-box--left ff--3 fs--22" style={{"maxWidth":486,"marginTop":0,"paddingLeft":0,"marginBottom":7,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"font-weight: bold;\">Obecný hostinec vo Veľkých Topoľčanoch</span><br>"}>
              </Title>

              <Image className="--left" style={{"maxWidth":349,"marginTop":0,"marginBottom":0}} src={"https://cdn.swbpg.com/t/7746/146d352844244dd9a32bcc76c83b3605_s=860x_.jpg"} svg={false} href={"/blog-clanok-historia"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7746/146d352844244dd9a32bcc76c83b3605_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7746/146d352844244dd9a32bcc76c83b3605_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7746/146d352844244dd9a32bcc76c83b3605_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7746/146d352844244dd9a32bcc76c83b3605_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7746/146d352844244dd9a32bcc76c83b3605_s=2000x_.jpg 2000w"} position={{"x":"-2.588235294117647%","y":"-1.9704433497536946%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}} name={"mzwxhwkcrr"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingTop":0}} columns={"1"}>
            
            <ColumnWrapper className="--hvr5" style={{"paddingTop":0,"paddingBottom":0}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0,0,0,1)"}} name={"6udx0j7rhv2"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(217, 217, 219, 1)"}} name={"4r1xitw2gf"}>
        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(231, 231, 231, 1)"}} name={"4j4tceh1ihc"}>
        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"iphsybo5f4"}>
        </SeparateLine>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(16, 44, 43, 1)"}} name={"y8rp4x6byjj"}>
        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255,255,255,1)"}} name={"grubqerprfd"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"hhrcbmpx43d"}>
        </SeparateLine>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"ig52bublaw"}>
        </SeparateLine>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255,255,255,1)"}} name={"ii4sm6yxsjj"}>
        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 1)"}} name={"qcfc8zsb2pq"} layout={"l4"}>
        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"spathore"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--hvr2" style={{"maxWidth":2000}}>
              
              <Button className="btn-box btn-box--style2 btn-box--right ff--3 fs--13 ls--08" href={"#uvod1"} content={"<span style=\"font-weight: bold;\">Späť hore</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"1ecirf6syiu"}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"hiccjjwjzo"}>
          
          <ColumnWrap className="column__flex el--2 --full flex--center" style={{"paddingTop":0,"paddingBottom":6.4140625}} columns={"2"} fullscreen={true}>
            
            <ColumnWrapper className="--hvr5 js-anim  --anim3" style={{"marginBottom":112.859375,"paddingBottom":8.0625}} anim={"3"}>
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<span style=\"font-weight: bold; color: rgb(155, 155, 155);\">Spojte sa s nami</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <ContactForm className="--shape2 --shape3 --style3 ff--3 fs--20" action={"/contact"} fields={[{"name":"","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Odoberať novinky","type":"submit","align":"left"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"andxfwp9ii"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim5" anim={"5"}>
              
              <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=860x_.png"} svg={false} href={"https://www.facebook.com/Tribe%C4%8Dsk%C3%A9-m%C3%BAzeum-Topo%C4%BE%C4%8Dany-918391598311409/?epa=SEARCH_BOX"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/adaac89956384953972eaf4ebf0bcc41_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim3" anim={"3"}>
              
              <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=860x_.png"} svg={false} href={"https://www.instagram.com/tribecske_muzeum/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/2138ded215e04bd5bc46083a3b6922d0_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim2" anim={"2"}>
              
              <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=860x_.png"} svg={false} href={"https://www.youtube.com/channel/UC_9B5K30zlouITbYYku8jjg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/56da973fc65d485abd6f2be6e25d2f72_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 js-anim  --anim4" anim={"4"}>
              
              <Image style={{"maxWidth":40}} src={"https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=860x_.png"} url={"https://tribecskem.podbean.com/"} use={"url"} href={"https://tribecskem.podbean.com/"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/1af4ccd94ed4455f8b0b071f7121468b_s=1400x_.png 1400w"} RootClassName={"column__icon"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"88zdfvydbd7"}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"09fyx8b9slvf"}>
          
          <ColumnWrap className="column__flex --center el--4" style={{"paddingBottom":4.546875,"backgroundColor":"rgba(42, 41, 41, 1)"}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(240, 240, 242);\">Dokumenty</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://cdn.swbpg.com/d/7746/zriadovacia-listina-pdf\" target=\"_blank\">Zriaďovacia listina</a></span>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://cdn.swbpg.com/d/7746/koncepcia-pdf\" target=\"_blank\">Koncepcia akvizičnej činnosti</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://cdn.swbpg.com/d/7746/oznamovanie-protispolocenskej-cinnosti-2024.docx\" target=\"_blank\">Oznamovanie protispoločenskej činnosti</a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Polstoročnica&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/polstorocnica-muzea\">Polstoročnica múzea</a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">História&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/historia-muzea\">História múzea</a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Knižnica</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<a href=\"/kniznica\" style=\"font-weight: bold;\">Knižnica múzea</a><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/ponuka-tlacovin\">Ponuka tlačovín</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(240, 240, 242);\">Zverejňovanie</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Smernice</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Faktúry</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Objednávky</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Zmluvy</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Súhrnné správy</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Verejné obstarávanie</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/zverejnovanie\">Profil verejného obstarávateľa   </a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Ochrana osobných údajov</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/ochrana-osobnych-udajov\">Ochrana osobných údajov   </a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(240, 240, 242);\">Projekty</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/projekty\">Dotácia MK SR</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<a href=\"/projekty\" style=\"font-weight: bold;\">Dotácia FPU</a><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/projekty\">- Akvizícia kníh</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18 ls--002" content={"<a href=\"/projekty\" style=\"font-weight: bold;\">- Akvizícia zbierkových predmetov&nbsp;</a><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<a href=\"/projekty\" style=\"font-weight: bold;\">- Kamerový systém</a><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Kalendárium</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://cdn.swbpg.com/d/7746/letak2024.pdf\" target=\"_blank\">Prehľad podujatí na rok 2024</a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Kontakt</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<a href=\"/kontakt\" style=\"font-weight: bold;\">Kontaktujte nás</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(240, 240, 242);\">Múzejné novinky</span>\n"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<a href=\"https://en.calameo.com/read/006684035a059dae5fc8b\" target=\"_blank\"><span style=\"font-weight: bold; color: rgb(74, 144, 226);\">Múzejné novinky</span><span style=\"font-weight: bold;\"> 2017</span></a><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<a href=\"https://en.calameo.com/read/006684035d6edad7d4bc8\" target=\"_blank\"><span style=\"font-weight: bold; color: rgb(74, 144, 226);\">Múzejné novinky</span><span style=\"font-weight: bold;\"> 2018</span></a><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<a href=\"https://en.calameo.com/read/006684035207a5e047e12\" target=\"_blank\"><span style=\"font-weight: bold; color: rgb(74, 144, 226);\">Múzejné novinky</span><span style=\"font-weight: bold;\"> 2019</span></a><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<a href=\"https://en.calameo.com/read/0066840355fde1d55087a\" target=\"_blank\"><span style=\"font-weight: bold; color: rgb(74, 144, 226);\">Múzejné novinky</span><span style=\"font-weight: bold;\"> 20</span><span style=\"font-weight: bold;\">20</span></a><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://en.calameo.com/read/006684035e379d720998f\" target=\"_blank\"><span style=\"color: rgb(74, 144, 226);\">Múzejné novinky</span> 2021</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://en.calameo.com/read/006684035772d7909bfba\" target=\"_blank\"><span style=\"color: rgb(74, 144, 226);\">Múzejné novinky</span> 2022</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://www.calameo.com/read/006684035b107e0e7a4ea\" target=\"_blank\"><span style=\"color: rgb(74, 144, 226);\">Múzejné novinky</span> 2023</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--center ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"https://www.calameo.com/read/00668403528e0248f1a44\"><span style=\"color: rgb(74, 144, 226);\">Múzejné novinky</span> 2024</a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Ponuka podujatí</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/podujatia\">Tvorivé dielne</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/podujatia\">Výtvarné podujatia&nbsp;</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: bold;\"><a href=\"/podujatia\">Prednášky</a></span><br>"}>
              </Text>

              <Title className="title-box title-box--center ff--3 fs--20" content={"<span style=\"color: rgb(255, 255, 255);\">Výstavy</span><br>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--18" content={"<span style=\"font-weight: 700;\"><a href=\"/#vystavy\">Výstavy</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"w6d96a5x4r"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="--hvr2">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr2">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr2">
            </ColumnWrapper>

            <ColumnWrapper className="--hvr2">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"vh87j70o12d"}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"rv48ysythk"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1070}}>
              
              <Subtitle className="subtitle-box ff--3 fs--16" content={"<span style=\"color: rgb(155, 155, 155);\">Podnety podľa zák. č. 54/2019 Z. z. o ochrane oznamovateľov možno podávať na adrese: </span><span style=\"color: rgb(255, 255, 255);\"><a href=\"mailto:sekretariat@tribecskemuzeum.sk\" target=\"_blank\">sekretariat@tribecskemuzeum.sk</a></span><span style=\"color: rgb(155, 155, 155);\">&nbsp;</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"vh87j70o12d"}>
          
          <SeparateLineWrap className="--invert">
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"cgvrsvfjkta"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--hvr2 js-anim  --anim2" anim={"2"}>
              
              <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--13 w--300 ls--08" href={"#uvod1"} content={"<span style=\"font-weight: bold;\">Späť hore</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42, 41, 41, 1)"}} name={"r0kbxy6b9r"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image className="--left" style={{"maxWidth":150}} src={"https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=660x_.png"} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/0b6390fe717b4e12bacc85c98c8b63c1_s=860x_.png 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(42,41,41,1)"}} name={"z8yy4gf49ug"}>
        </SeparateLine>


        <Column style={{"paddingTop":4,"paddingBottom":6.3203125,"backgroundColor":"rgba(0,0,0,1)"}} name={"shg9p27kh5r"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"rgba(0,0,0,1)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--left ff--3 fs--14" content={"<span style=\"color: rgb(155, 155, 155);\">© 2023 Tribečské múzeum v Topoľčanoch, všetky práva vyhradené</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}